<script setup lang="ts">

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { detect } from 'detect-browser';

const browser = detect();

// Minimum required browser versions
const chromeRequiredVersion = 96;
const firefoxRequiredVersion = 95;
const edgeChromiumRequiredVersion = 95;
const safariRequiredVersion = 14;
const operaRequiredVersion = 80;

let browserUpToDate = true;

if (browser && 'version' in browser) {
  switch (browser && browser.name) {
    case 'chrome':
      if (parseInt(browser.version ?? '0', 10) < chromeRequiredVersion) {
        browserUpToDate = false;
      }
      break;
    case 'firefox':
      if (parseInt(browser.version ?? '0', 10) < firefoxRequiredVersion) {
        browserUpToDate = false;
      }
      break;
    case 'edge-chromium':
      if (parseInt(browser.version ?? '0', 10) < edgeChromiumRequiredVersion) {
        browserUpToDate = false;
      }
      break;
    case 'safari':
      if (parseInt(browser.version ?? '0', 10) < safariRequiredVersion) {
        browserUpToDate = false;
      }
      break;
    case 'opera':
      if (parseInt(browser.version ?? '0', 10) < operaRequiredVersion) {
        browserUpToDate = false;
      }
      break;
    default:
      browserUpToDate = true;
  }
}
</script>

<template>
  <div
    v-if="!browserUpToDate"
    class="flex w-full items-center justify-center bg-[#1261B1] bg-gradient-to-b from-blue-900
    p-3 text-xs font-normal leading-6 text-white"
  >
    <span class="mr-3 inline-flex size-8 shrink-0 items-center justify-center rounded-lg bg-blue-500 text-sm">
      <FontAwesomeIcon :icon="['fas', 'exclamation']" />
    </span>
    <span>
      <strong class="font-medium">Let op: </strong>
      <a
        href="https://www.google.com/chrome/"
        target="_blank"
        class="underline transition-colors hover:text-blue-300"
      >Chrome</a>,
      <a
        href="https://www.mozilla.org/firefox/new/"
        target="_blank"
        class="underline transition-colors hover:text-blue-300"
      >Firefox</a> of
      <a
        href="https://www.microsoft.com/edge"
        target="_blank"
        class="underline transition-colors hover:text-blue-300"
      >Edge</a>
      is verouderd. Upgraden naar de nieuwste versie verbetert uw browsing-ervaring.
    </span>
  </div>
</template>
